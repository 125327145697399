
import { defineComponent, onMounted, ref } from "vue";
import { useRoute, useRouter } from "vue-router";
import FsLightbox from "fslightbox-vue/v3";
import {
  ApiBase,
  ApiFulfillmentOrders,
  ApiQualityInspection,
  ApiStock,
} from "@/core/api";
import AddQualityInspection from "@/views/warehouse-management/stock-in/AddQualityInspection.vue";
import mixin from "@/mixins";
import _ from "lodash";
import Swal from "sweetalert2/dist/sweetalert2.js";
import { useI18n } from "vue-i18n";
import {
  commonBackToList,
  setModuleBCN,
} from "@/core/directive/function/common";
import { ProductItem } from "@/core/directive/interface/order";
import CommonProduct from "@/components/table/CommonProduct.vue";
import { TaggingItem } from "@/core/directive/interface/common";
import PermissionCommon from "@/components/layout/PermissionCommon.vue";

export default defineComponent({
  name: "warehouse-management-stock-in-information",
  components: {
    AddQualityInspection,
    CommonProduct,
    FsLightbox,
    PermissionCommon,
  },
  setup() {
    const { t } = useI18n();

    const route = useRoute();
    const router = useRouter();
    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const loading = ref<boolean>(false);
    const formRef = ref<null | HTMLFormElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const formData = ref({
      id: route.params.id,
      stock_in_id: "",
      status: "",
      created_uid: "",
      warehouse_id: "",
      tracking_no: "",
      fulfillment_order_number: "",
      consigner: "",
      received_date: null,
      sku_info: {
        sku_gtin: "",
        merchant_article_no: "",
        color: "",
        size: "",
        received_quantity: 0,
        passed_quantity: 0,
        failed_quantity: 0,
        sku_identifier: "",
        origin_country: "",
        spu: "",
      },
      inventory_target: "",
      stock_in_quantity: "",
      quality_inspection: [],
      __show: {
        status: "",
      },
    });
    const options = ref({
      origin_country: [] as TaggingItem[],
      readyLoading: true,
      statusLoading: false,
      status: new Map([]),
      created_uid: [] as TaggingItem[],
      warehouse: [] as TaggingItem[],
      consigner: "",
      inventory_target: [] as TaggingItem[],
      product_items: [] as ProductItem[],
      received_quantityed: false,
      received_quantity: 0,
      loadingProducts: true,
      toggler: false,
      sources: [] as any[],
      sourcesIndex: -1,
      sourcesSlide: 1,
    });

    const rules = ref({
      "sku_info.sku_gtin": [
        {
          type: "string",
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (
                value == "" &&
                formData.value.sku_info.merchant_article_no == ""
              ) {
                reject(t("stockIn.skuInfoIsRequired"));
              } else {
                resolve();
              }
            });
          },
        },
      ],
      "sku_info.merchant_article_no": [
        {
          type: "string",
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (value == "" && formData.value.sku_info.sku_gtin == "") {
                reject(t("stockIn.skuInfoIsRequired"));
              } else {
                resolve();
              }
            });
          },
        },
      ],
      // "sku_info.color": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      // "sku_info.size": [
      //   {
      //     required: true,
      //     message: t("common.isRequired"),
      //     trigger: "change",
      //   },
      // ],
      "sku_info.received_quantity": [
        {
          type: "number",
          asyncValidator: (rule, value) => {
            return new Promise<void>((resolve, reject) => {
              if (Number(value) !== formData.value.quality_inspection.length) {
                reject(t("common.confirmReceivedQuantity"));
              } else {
                resolve();
              }
            });
          },
          trigger: "change",
        },
      ],
    });

    const parentQualityInspectionId = ref(0);

    const getShowInfo = async (isNew = false) => {
      const { data } = await ApiStock.showStockIn({ id: route.params.id });
      if (data.code == 0) {
        if (isNew === true) {
          formData.value.quality_inspection = data.data.quality_inspection;
        } else {
          formData.value = data.data;
          if (data.data.created_uid != "" && data.data.created_uid != 0) {
            getUserSourceData("", data.data.created_uid);
          }
          options.value.consigner =
            data.data.consigner_location.name +
            ", " +
            data.data.consigner_location.street +
            ", " +
            data.data.consigner_location.zip_code +
            ", " +
            data.data.consigner_location.city +
            ", " +
            data.data.consigner_location.country_iso_2;
          getProductsData({
            sku_id: [data.data.sku_info.sku_identifier],
            merchant_id: 0,
          });
        }
      }
    };

    const getWarehouseData = async () => {
      const { data } = await ApiFulfillmentOrders.getWarehouseData({});
      if (data.code == 0) {
        options.value.warehouse = data.data;
      }
    };

    const getUserSourceData = async (value?: string, id?: string) => {
      let params =
        value != ""
          ? {
              search_value: value,
            }
          : {
              id: id,
            };

      const { data } = await ApiBase.getUserSourceData(params);
      if (data.code == 0) {
        options.value.created_uid = data.data;
      }
    };
    const debounceUserSearch = _.debounce(getUserSourceData, 1000);
    const searchTaskOwnerItems = (query) => {
      debounceUserSearch(query);
    };

    const getTaggingData = async () => {
      // "stock_In_status",
      const { data } = await ApiBase.getTaggingData({
        short_key: ["stock_in_inventory_target"],
      });
      if (data.code == 0) {
        // data.data.stock_In_status.items.map((item, index) => {
        //   options.value.status.set(item.value, item.label);
        // });
        options.value.inventory_target =
          data.data.stock_in_inventory_target.items;
      }
    };

    const getFromInfo = async () => {
      loading.value = true;
      Promise.all([
        getShowInfo(),
        getWarehouseData(),
        getTaggingData(),
        getCountryData(),
      ])
        .then(() => {
          loading.value = false;
          options.value.readyLoading = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleEntryStockIn = async () => {
      Swal.fire({
        text: t("common.stockInConfirmation"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          options.value.statusLoading = true;
          const { data } = await ApiStock.entryStockIn({
            id: route.params.id,
          });
          options.value.statusLoading = false;
          if (data.code == 0) {
            getShowInfo();
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const addQualityInspection = () => {
      loading.value = true;
      if (submitButton.value) {
        submitButton.value.setAttribute("data-kt-indicator", "on");
      }
      ApiQualityInspection.addQualityInspection({
        stock_in_id: formData.value.id,
        sku_identifier: formData.value.sku_info.sku_identifier,
        inventory_id: 0,
        warehouse: formData.value.warehouse_id,
      })
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            getShowInfo(true);
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleAddQualityInspection = () => {
      parentQualityInspectionId.value = 0;
      addQualityInspection();
    };

    const handleEditQualityInspection = (row) => {
      parentQualityInspectionId.value = row.id;
    };

    const handleDeleteQualityInspection = (index, row) => {
      Swal.fire({
        text: t("common.secondConfirmation"),
        icon: "warning",
        showCancelButton: true,
        buttonsStyling: false,
        confirmButtonText: t("common.yes"),
        cancelButtonText: t("common.no"),
        customClass: {
          confirmButton: "btn btn-primary",
          cancelButton: "btn btn-danger",
        },
      }).then(async (result) => {
        if (result.isConfirmed) {
          loading.value = true;
          const { data } = await ApiQualityInspection.deleteQualityInspection({
            id: row.id,
          });
          loading.value = false;
          if (data.code == 0) {
            Swal.fire({
              text: t("common.deletedSuccessfully"),
              icon: "success",
              buttonsStyling: false,
              confirmButtonText: t("common.okGotIt"),
              customClass: {
                confirmButton: "btn btn-primary",
              },
            }).then(() => {
              getShowInfo(true);
            });
          } else {
            showServerErrorMsg(data);
          }
        }
      });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }
      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          ApiStock.updateStockIn(formData.value)
            .then(({ data }) => {
              loading.value = false;
              submitButton.value?.removeAttribute("data-kt-indicator");
              if (data.code == 0) {
                showFormSubmitSuccessMsg(() => {
                  getShowInfo();
                });
              } else {
                showServerErrorMsg(data);
              }
            })
            .catch((error) => {
              console.log(error);
            });
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const getSummaries = (param) => {
      const { columns, data } = param;
      let sums: string[] = [];
      let failedL = 0,
        total = 0;
      columns.forEach((column, index) => {
        const values = data.map((item) => item.inspection_status);
        if (index == 3) {
          // console.log(values);
          failedL =
            values.reduce(function (prev, next) {
              prev[next] = prev[next] + 1 || 1;
              return prev;
            }, {})[10] || 0;
          total = values.length;
        }
      });
      // console.log(failedL);
      // console.log(total);
      sums[0] = `${t("common.total")}：${total}`;
      sums[1] = `${t("common.defectives")}：${failedL}`;
      sums[2] = `${t("common.finishedGoods")}：${total - failedL}`;
      return sums;
    };

    const getCountryData = async () => {
      const { data } = await ApiBase.getCountryData();
      if (data.code == 0) {
        options.value.origin_country = data.data;
      }
    };

    const getStatusHtml = (status) => {
      let statusClass = "",
        str = "";
      switch (status) {
        case "5":
          str = "In Transit"; // 包裹接收
          statusClass = "badge-warning";
          break;
        case "10":
          str = "Package Received"; // 包裹接收
          statusClass = "badge-light-primary";
          break;
        case "20":
          str = "Source Matching"; // 来源匹配
          statusClass = "badge-light-info";
          break;
        case "30":
        case "40":
          str = status == "30" ? "Checking" : "Product Matching"; // 检查中 商品匹配
          statusClass = "badge-light-warning";
          break;
        case "50":
          str = "Checking Abnormal"; // 检查异常
          statusClass = "badge-light-danger";
          break;
        case "60":
        case "70":
          str = status == "60" ? "Checking Completed" : "Ready For Storage"; // 检查完毕 准备入库
          statusClass = "badge-light-info-custom";
          break;
        case "80":
          str = "Storage Completed"; // 入库完毕
          statusClass = "badge-light-success";
          break;
        default:
          statusClass = "badge-light-primary";
      }
      return statusClass;
    };

    const getProductsData = async (ids) => {
      options.value.loadingProducts = true;
      const { data } = await ApiBase.getProductsData(ids);
      options.value.loadingProducts = false;
      if (data.code == 0) {
        options.value.product_items = data.data;
      }
    };

    const previewImages = (obj) => {
      console.log(obj);
      options.value.sources = obj.sources;
      options.value.sourcesIndex = obj.sourcesIndex;
      options.value.sourcesSlide = obj.sourcesSlide;
      options.value.toggler = obj.toggler;
    };

    const backToList = () => {
      commonBackToList(router, "/warehouse-management/stock-in");
    };

    onMounted(() => {
      setModuleBCN(t, route, router);
      getFromInfo();
    });

    return {
      t,
      loading,
      options,
      formData,
      formRef,
      rules,
      submitButton,
      parentQualityInspectionId,
      getFromInfo,
      searchTaskOwnerItems,
      getShowInfo,
      handleEntryStockIn,
      handleAddQualityInspection,
      handleEditQualityInspection,
      handleDeleteQualityInspection,
      submit,
      getSummaries,
      getStatusHtml,
      backToList,
      previewImages,
    };
  },
});
