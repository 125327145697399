
import { defineComponent, ref, watch, onMounted } from "vue";
import { hideModal } from "@/core/helpers/dom";
import { ApiFulfillmentOrders, ApiQualityInspection } from "@/core/api";
// import Swal from "sweetalert2/dist/sweetalert2.js";
import mixin from "@/mixins";
import { useI18n } from "vue-i18n";

export default defineComponent({
  name: "add-service-profile-modal",
  props: {
    id: { type: Number, default: 0 },
    parentId: { type: Number, default: 0 },
  },
  components: {},
  emits: ["update-list", "reset-form"],
  setup(props, { emit }) {
    const { t } = useI18n();

    const formRef = ref<null | HTMLFormElement>(null);
    const addQualityInspectionModalRef = ref<null | HTMLElement>(null);
    const submitButton = ref<HTMLElement | null>(null);
    const loading = ref<boolean>(false);

    const {
      showValidateErrorMsg,
      showFormSubmitSuccessMsg,
      showServerErrorMsg,
    } = mixin();

    const formData = ref({
      stock_in_id: props.parentId,
      inventory_id: "",
      sku_identifier: "",
      warehouse: "",
      inspection_status: "", // Failed:10,Passed:20
      inspection_notes: "",
    });

    const options = ref({
      warehouse: [],
      inspection_status: [
        {
          label: t("inspectionReport.failed"),
          value: 10,
        },
        {
          label: t("inspectionReport.passed"),
          value: 20,
        },
      ],
    });

    watch(
      () => props.id,
      (newVal) => {
        console.log(newVal);
        if (newVal) {
          getFormData(newVal);
        } else {
          resetForm();
        }
      }
    );

    const rules = ref({});

    const getFormData = (id) => {
      if (id) {
        loading.value = true;
        ApiQualityInspection.showQualityInspection({
          id: id,
        })
          .then(({ data }) => {
            loading.value = false;
            if (data.code == 0) {
              formData.value = data.data;
            }
          })
          .catch((error) => {
            console.log(error);
          });
      }
    };

    const getWarehouseData = async () => {
      const { data } = await ApiFulfillmentOrders.getWarehouseData({});
      if (data.code == 0) {
        options.value.warehouse = data.data;
      }
    };

    const getFromInfo = () => {
      Promise.all([getWarehouseData()])
        .then(() => {
          loading.value = false;
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const submit = () => {
      if (!formRef.value) {
        return;
      }

      formRef.value.validate((valid) => {
        if (valid) {
          if (loading.value) return;
          loading.value = true;
          if (submitButton.value) {
            submitButton.value.setAttribute("data-kt-indicator", "on");
          }
          if (props.id) {
            editRelationship();
          } else {
            addQualityInspection();
          }
        } else {
          showValidateErrorMsg();
          return false;
        }
      });
    };

    const addQualityInspection = () => {
      ApiQualityInspection.addQualityInspection(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              resetForm();
              hideModal(addQualityInspectionModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const editRelationship = () => {
      ApiQualityInspection.updateQualityInspection(formData.value)
        .then(({ data }) => {
          loading.value = false;
          submitButton.value?.removeAttribute("data-kt-indicator");
          if (data.code == 0) {
            showFormSubmitSuccessMsg(() => {
              // resetForm();
              hideModal(addQualityInspectionModalRef.value);
              emit("update-list");
            });
          } else {
            showServerErrorMsg(data);
          }
        })
        .catch((error) => {
          console.log(error);
        });
    };

    const handleDiscard = () => {
      resetForm();
      hideModal(addQualityInspectionModalRef.value);
      emit("reset-form");
    };

    const resetForm = () => {
      formRef.value?.resetFields();
    };

    onMounted(() => {
      getFromInfo();
    });

    return {
      t,
      loading,
      formData,
      rules,
      formRef,
      addQualityInspectionModalRef,
      submitButton,
      options,
      getFormData,
      submit,
      handleDiscard,
      resetForm,
    };
  },
});
